<template>
	<section>
		<div class="banner">
			<div class="section">
				<div class="section-content banner-con">
					<div class="top-title">助力客户数字化建设</div>
					<div class="top-text">
						<p>让客户拥有自己的营销中心，从上到下快速传递产品信息</p>
						<p>及时响应客户的需求，助力销售业绩成倍增长</p>
					</div>

				</div>
			</div>
		</div>
		<!-- <div class="page-content"> -->
			
			
			<div class="section">
				<div class="section-content">
					<div class="part-title">德利净水</div>
					<div class="part-con">
						<div class="part-d part-left">
							<img class="code" src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/case/delijinshui/delijinshui01.jpg">
							<div class="part-c-title">德利净水</div>
							<p class="part-c-intro">智能净水 健康安心</p>
						</div>
						<div class="part-d part-right">
							<img class="pr-img1" src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/case/delijinshui/delijinshui02.png">
							<img class="pr-img2" src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/case/delijinshui/delijinshui03.png">
						</div>
					</div>
                    <div class="part-title">聚码科技</div>
                    <div class="part-con">
                        <div class="part-d part-left">
                            <img class="code" src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/case/gorex/gorex01.jpg">
                            <div class="part-c-title">聚码科技</div>
                            <p class="part-c-intro">搭建多方位营销矩阵为企业搭建一站式营销中心可轻松对接多个营销小程序</p>
                        </div>
                        <div class="part-d part-right">
                            <img class="pr-img1" src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/product_02p.png">
                            <img class="pr-img2" src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/product_03p.png">
                        </div>
                    </div>
				</div>
			</div>
		<!-- </div> -->
	</section>
</template>
<script type="text/javascript">
	export default {
    data(){
        return {
        	url:''
        }
    },
	mounted(){
		console.log('this is index')
	},
	methods: {

	},
  
  // router
}

</script>
<style type="text/css" scoped>
	.banner{
		width: 100%;
		height: 300px;
		background-image: url(https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/b4666.png);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		background-color: #fff;
		overflow: hidden;
		color: #fff;
	}
	.banner-con{
		padding-left: 120px;
	}
    .top-title{
		font-size: 24px;
		line-height: 24px;
		font-weight: bolder;
		margin-top: 80px;
	}
	.top-text{
		font-size: 15px;
		font-weight: 300;
		padding-top: 6px;
	}


    .part-title{
    	font-size: 28px;
    	font-weight: bold;
    	line-height: 28px;
    	color: #333333;
    	margin-top: 80px;
    }
    .part-con{
    	width: 100%;
    	border-radius: 30px;
        background-color: #fff;
        margin-top: 40px;
        overflow: hidden;
        padding: 30px 60px 40px 60px;
        display: flex;
        align-items: center;
    }
    .part-d{
    	width: 50%;
    }
    .part-left{
    	display: flex;
    	align-items: center;
    	justify-content: center;
    	flex-direction: column;
    }
    .part-c-title{
    	font-size: 24px;
    	font-weight: bolder;
    	color: #333333;
    	margin-top: 40px;
    }
    .part-c-intro{
    	font-size: 18px;
    	margin-top: 20px;
    	color: #909399;
    	/*transform: scale(0.8);*/
    	font-weight: lighter;
    	font-family: "Lato-Light";
    }
    .part-right{
    	flex-shrink: 0;
    	margin-left: 180px;
    	display: flex;
    	align-items: flex-end;
    	justify-content: flex-end;
    }
    .code{
    	width: 200px;
    }
    .part-right .pr-img1{
    	width: 280px;
    }
    .part-right .pr-img2{
    	width: 240px;
    	margin-left: 20px;
    }
</style>